


































































































































































































































































































































































































































































































































































































































































.register-card {
  // height: 44.4rem;
  .el-form {
    padding-bottom: 0px;
    .sliding-verification {
      .el-form-item__content .el-button {
        width: 280px;
        // width: 2.8rem;
        height: 36px;
        line-height: 34px;
        font-size: 14px;
      }
      .is-success-verify {
        background: #ade787;
        cursor: default !important;
      }
    }
    .el-input {
      width: 280px;
      // width: 2.8rem;
      // height: 0.36rem;

      .el-input__inner {
        // height: 100%;
      }
    }
    .verification-code {
      .el-form-item__content {
        .el-input {
          width: 150px;
          // width: 1.24rem;
          // height: 0.36rem;

          .el-input__inner {
            // height: 100%;
          }
        }
        .get-code {
          display: inline-block;
          text-align: center;
          width: 120px;
          height: 36px;
          line-height: 36px;
          border-radius: 10px;
          margin-left: 12px;

          // width: 1.24rem;
          // height: 0.36rem;
          // line-height: 0.36rem;
          // border-radius: 10px;
          // margin-left: 0.32rem;
          &.get-code-status1 {
            color: #595959;
            background: #e5e5e3;
            cursor: not-allowed;
          }
          &.get-code-status2 {
            color: #cb7e09;
            background: #ffecbe;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .form-empty {
      height: 0;
      margin-bottom: 0 !important;
      overflow: hidden;
    }
  }
  .bottom-btns {
    margin-bottom: 20px;
    .el-button {
      width: 280px;
      margin-left: 100px;

      // width: 2.8rem;
      // margin-left: 1rem;
    }
  }
  .bottom-text {
    margin-top: 28px;
    text-align: center;
    p,
    span {
      color: #9bd277;
    }
    .please-agree {
    }
    .line-login {
      margin-top: 15px;
      font-size: 12px;
      text-align: center;
      color: #6aaf3d;
      font-family: DFPFangYuanW7 !important;
      .span-login {
        color: #fcae2c;
        font-family: DFPFangYuanW7 !important;

        &:hover {
          color: #ff8626;
          font-family: DFPFangYuanW7 !important;
        }
      }
    }
  }
}
