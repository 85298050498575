


























































































































































































































































































































































































































.login-card {
  .forget-pwd {
    position: absolute;
    top: 40px;
    right: 106px;
    font-size: 12px;
    color: #9bd277;
    cursor: pointer;
    &:hover {
      color: #6aaf3d;
    }
  }

  .login-input {
    width: 280px;
    // width: 2.8rem;
    // height: 0.36rem;
  }
  .el-input__prefix {
    display: inline-block;
    font-size: 12px;
    height: 40px;
    position: absolute;
    top: 0.4rem;
    left: 2px;
    cursor: pointer;
    overflow: hidden;
    opacity: 1;
  }
  .login-btn-box {
    text-align: center;
    position: relative;
    .button-item {
      width: 280px;
      // margin-left: 0.2rem;
    }

    .login-btn-mask {
      display: inline-block;
      width: 2.8rem;
      width: 280px;

      height: 42px;
      background: transparent;
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -140px;
    }
  }
  .register-account {
    margin-top: 30px;
    font-size: 12px;
    text-align: center;
    color: #6aaf3d;
    font-family: DFPFangYuanW7 !important;
    .span-register {
      color: #fcae2c;
      font-family: DFPFangYuanW7 !important;

      &:hover {
        color: #ff8626;
        font-family: DFPFangYuanW7 !important;
      }
    }
  }
}
